(function ($) {

	const CONSENT_OPTIONS = {
		ALL_GRANTED: {
			'ad_storage': 'granted',
			'ad_user_data': 'granted',
			'ad_personalization': 'granted',
			'analytics_storage': 'granted',
			"personalization_storage": "granted",
		},
		ALL_DENIED: {
			'ad_storage': 'denied',
			'ad_user_data': 'denied',
			'ad_personalization': 'denied',
			'analytics_storage': 'denied',
			"personalization_storage": "denied",
		},
		DEFAULTS: {
			'ad_storage': 'denied',
			'ad_user_data': 'denied',
			'ad_personalization': 'denied',
			'analytics_storage': 'denied',
			"personalization_storage": "denied",
			"functionality_storage": 'granted',	// functionality of the website or app, e.g. language settings.
			"security_storage": "granted" // authentication functionality, fraud prevention, etc
		}
	}

	function setCookie(c_name, value, exdays) {
		var exdate = new Date();
		exdate.setDate(exdate.getDate() + exdays);
		var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString() + "; path=/");
		document.cookie = c_name + "=" + c_value;
	}

	function getCookie(c_name) {
		var i, x, y, ARRcookies = document.cookie.split(";");
		for (i = 0; i < ARRcookies.length; i++) {
			x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
			y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
			x = x.replace(/^\s+|\s+$/g, "");
			if (x == c_name) {
				return unescape(y);
			}
		}
	}

	function hideCookieAlert() {
		$("#cookielawwrapper").fadeOut("slow", function () { $(this).remove(); });
	}

	function updateCookiesConsent(consentOptions) {
		consentOptions = $.extend(CONSENT_OPTIONS.DEFAULTS, consentOptions);
		gtag("consent", "update", consentOptions);
	}

	function getUserRegion() {

		// Relies on the runtime's default timezone.
		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		return timezone.split("/")[0];
	}

	$.fn.cookiesManager = function (options) {

		// If Europe only is set, don't show popup nor implement consent mode
		if (options.europeonly === true && getUserRegion() !== "Europe") {
			return false;
		}

		$('body').on('click', '#allowcookiesBtn', function () {
			setCookie("allowcookies", true, 365);
			updateCookiesConsent(CONSENT_OPTIONS.ALL_GRANTED);
			hideCookieAlert();
		});

		$('body').on('click', '#declinecookiesBtn', function () {
			setCookie("allowcookies", false, 365);
			updateCookiesConsent(CONSENT_OPTIONS.ALL_DENIED);
			hideCookieAlert();
		});

		const areAllCookiesAllowed = getCookie("allowcookies");

		if (areAllCookiesAllowed === undefined) { 	// User has not choosen to grant or deny consent yet
			const defaults = {
				message: "",
				moreinfohref: "",
				moreinfotext: "",
				agreetext: "",
				disagreetext: "",
				animate: false,
				europeonly: false
			};

			var options = $.extend(defaults, options);

			// Create actual popup's html
			const html = "<div id='cookielawwrapper'>" +
				"<div class='cookielawbackdrop'></div>" +
				"<div class='cookielaw'>" +
				"<div class='cookiecontainer'>" +
				"<p>" + options.message + "<a id='cookiesmoreinfo' href='" + options.moreinfohref + "'>" + options.moreinfotext + "</a>.</p>" +
				"<a id='declinecookiesBtn' href='#'>" + options.disagreetext + "</a>" +
				"<a class='button ml-3' id='allowcookiesBtn' href='#'>" + options.agreetext + "</a>" +
				"</div>" +
				"</div>" +
				"</div>";

			$(this).prepend(html); // Add popup to page

			if (options.animate) {
				$(".cookielaw").slideDown('slow');
			} else {
				$(".cookielaw").show();
			}
		}
		else if (areAllCookiesAllowed == "true") {
			updateCookiesConsent(CONSENT_OPTIONS.ALL_GRANTED); 		// Persist user's choice
			setCookie("allowcookies", true, 365); 	// Reset cookie's expiration date
		}
		else {
			updateCookiesConsent(CONSENT_OPTIONS.ALL_DENIED);
			setCookie("allowcookies", false, 365);
		}
	};

})(jQuery);